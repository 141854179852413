<template>
  <div v-if="data" class="d-flex flex-column align-center text-center" style="gap: 16px">
    <div>
      You've been sent a payment link for {{ displayAmount(data.amount) }} from <b>{{ data.siteDisplayName || data.legalEntityName }}</b
      >.
    </div>
    <div v-if="receiptError" class="text-red">{{ receiptError }}</div>
    <div v-else-if="data.originalRecipientEmail && !alternateRecipient">
      <div>
        We'll send you a receipt to: <b>{{ data.originalRecipientEmail }}</b>
      </div>
      <div class="mt-2">
        <span class="text-decoration-underline cursor-pointer" @click="handleAlternateRecipientEmail">Click here</span> to receive it elsewhere.
      </div>
    </div>
    <div v-else class="d-flex flex-column" style="gap: 12px">
      <span>Want us to send you an <b>email receipt</b>? Please enter your details:</span>
      <v-text-field
        v-model="form.email"
        type="email"
        placeholder="Email (optional)"
        variant="solo-filled"
        :error-messages="v$.email.$errors.map((e) => e.$message.toString())"
        clearable
      />
    </div>

    <div class="d-flex align-center" style="gap: 5px">
      <v-checkbox v-model="termsAccepted" style="display: inline-block" />
      <span class="text-left">
        <span @click="termsAccepted = !termsAccepted">I have read and agreed with {{ data.siteDisplayName || data.legalEntityName }}&nbsp;</span>
        <a :href="data.termsOfServiceUrl" target="_blank" rel="noopener noreferrer" class="terms">terms and conditions</a>
        <span @click="termsAccepted = !termsAccepted">&nbsp;for using this service.</span>
      </span>
    </div>
    <div class="text-center">
      <v-btn :disabled="!termsAccepted" size="x-large" class="font-weight-bold text-primary" @click="handlePayNowClick">Pay now</v-btn>
    </div>
  </div>

  <div v-else-if="error">
    <v-alert :color="(errorIsAnActualError ? 'red' : 'green') + ' lighten-2'" :type="errorIsAnActualError ? 'error' : 'success'" dark>
      <span class="font-weight-bold">{{ error.error }}</span
      ><br />
      <span v-if="errorIsAnActualError">Please refresh or contact <a href="mailto:help@yetipay.me">help@yetipay.me</a> if the issue persists.</span>
    </v-alert>
  </div>

  <div v-else class="text-center">
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from 'vue';
import BigNumber from 'bignumber.js';
import { useFetchLinkData } from '@/composables/useFetchLinkData';
import { useVuelidate } from '@vuelidate/core';
import { email } from '@vuelidate/validators';
import { useRoute } from 'vue-router';
import { postPaymentLinkReceipt } from '@/api/postCustomerReceiptEmail';

const linkId = useRoute().params.linkId as string;

const termsAccepted = ref(false);
const alternateRecipient = ref(false);

const form = reactive({ email: '' });
const v$ = useVuelidate({ email: { email, $autoDirty: false } }, form);
const receiptError = ref<string | undefined>();

const { data, error } = useFetchLinkData();

const errorIsAnActualError = computed(() => {
  if (!error) {
    return false;
  }
  if (error.value?.error === 'Payment link already paid') {
    return false;
  }
  return true;
});

const handleAlternateRecipientEmail = () => {
  alternateRecipient.value = true;
};

const displayAmount = (amount: { value: number; currency: string }) => {
  return new BigNumber(amount.value).dividedBy(100).toNumber().toLocaleString('en-UK', {
    style: 'currency',
    currency: amount.currency,
  });
};

const handlePayNowClick = async () => {
  if (receiptError.value) {
    openPayNowLink();
    return;
  }

  v$.value.$touch();
  if (!v$.value.$error && data.value?.internalUrl) {
    try {
      if (form.email) {
        await postPaymentLinkReceipt(linkId, form.email);
      }
      openPayNowLink();
    } catch {
      receiptError.value = "Unable to request email receipt, please click 'Pay now' again to continue anyway.";
    }
  }
};

const openPayNowLink = () => window.open(data.value?.internalUrl, '_self');
</script>

<style scoped>
.terms {
  color: #fff;
}
.terms:hover {
  color: #e34696;
}
</style>
